@tailwind base;
@tailwind components;
@tailwind utilities;

/*:root {*/
/*  --foreground-rgb: 0, 0, 0;*/
/*  --background-start-rgb: 214, 219, 220;*/
/*  --background-end-rgb: 255, 255, 255;*/
/*}*/

/*@media (prefers-color-scheme: dark) {*/
/*  :root {*/
/*    --foreground-rgb: 255, 255, 255;*/
/*    --background-start-rgb: 0, 0, 0;*/
/*    --background-end-rgb: 0, 0, 0;*/
/*  }*/
/*}*/

body {
    font-size: 14px;
    /*color: rgb(var(--foreground-rgb));*/
    /*background: linear-gradient(*/
    /*    to bottom,*/
    /*    transparent,*/
    /*    rgb(var(--background-end-rgb))*/
    /*  )*/
    /*  rgb(var(--background-start-rgb));*/
}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .1);
    border-radius: 5px;
}

.card_one {
    width: 332.6px;
    height: 529.13px;
}
